<template>
  <div class="flex w-full bg-img vx-row no-gutter items-center justify-center mt-10" id="page-login">
    <div class="vx-col">
      <div class="vx-row no-gutter justify-center items-center">
        <div class="vx-col lg:block">
          <center>
            <img src="@/assets/logo.png" alt="logo" class="logo mx-auto lg:w-1/4 mb-10" />
          </center>
        </div>


        <div class="vx-col mx-auto lg:w-3/4">
          <div class="p-8 login-tabs-container">

            <close-button url="/managepayments"/>

            <div class="mb-4 float-right mt-5">
              <h1 class="text-4xl oeno_portfolio_title">Payment Failure</h1>
            </div>

            <hr class="oeno_divider" />

            <h2 class="text-2xl oeno_portfolio_title_action"><br/><br/><br/>Sorry but your payment was not successfully, <br/> you will be redirect back in a moment.</h2><br/><br/><br/>


          </div>
        </div>
      </div>

      <footer-bar />

    </div>
  </div>
</template>

<script>
//import { HTTP } from "@/axios.js";

import CloseButton from "../../../components/pageparts/CloseButton.vue"
import FooterBar from "../../../components/pageparts/Footer.vue"

export default {
  data() {
    return {
    };
  },
  components: {
    CloseButton,
    FooterBar
  },
  mounted() {
    setTimeout( () => this.$router.push({ path: '/managepayments'}), 10000);
  },
  computed: {
  },
  methods: {
  },
};
</script>

<style>

</style>
